<template>
    <div class="builder-block" :class="spacerValue()">
        <div class="inner inner--small">
            <div v-html="processLinks(block.fields['one-col__content'][0].value)"></div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "OneColumnContent",
        props: {
            block: {
                type: Object,
                required: true
            }
        },
        methods: {
            spacerValue() {
                var spacer = this.block.fields['one-col__spacer'][0].value || '';
                if(spacer === 'xsmall') return 'mb-18'
                if(spacer === 'small') return 'mb-32'
                if(spacer === 'medium') return 'mb-54'
                if(spacer === 'large') return 'mb-80'
                if(spacer === 'xlarge') return 'mb-120'
            },
            processLinks(content) {
                content = content.replaceAll('href="https://', 'target="_blank" href="https://')
                content = content.replaceAll('href="mailto:', 'target="_blank" href="mailto:')
                return content;
            }
        },
    }
</script>

<style type="text/css" lang="scss">
    .builder-block {
        .inner {
            a {
                color: black;
                text-decoration: underline;

                &:hover {
                    color: blue;
                }
            }
        }
    }
</style>
